<template>
    <transition name="modal" v-if="isActive">
        <perfect-scrollbar class="modal-mask">
            <div class="modal-form modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4>
                            <b-icon icon="pencil-square"></b-icon>
                            <span>ออกรายงาน</span>
                        </h4>

                        <button class="btn-close" @click="hide(false)"><b-icon icon="x"></b-icon></button>
                    </div>
                    <div class="modal-body">
                        <div class="content-yellow-note mb-2">
                            <span><b-icon icon="bell" class="icon"></b-icon> ออกรายการสั่งซื้อเป็น Excel ไฟล์</span>
                        </div>
                        <div class="form">
                            <div class="input-field input-date-icon">
                                <datepicker
                                    v-model="form.dateFrom"
                                    :input-class="[ 'input-date', 'square' ]"
                                    :language="calendarTH"
                                    :full-month-name="true"
                                    calendar-class="datepicker-box"
                                    placeholder="ตั้งแต่วันที่"
                                    format="dd/MM/yyyy">
                                </datepicker>
                                <b-icon icon="calendar"></b-icon>
                            </div>
                            <div class="input-field input-date-icon">
                                <datepicker
                                    v-model="form.dateTo"
                                    :input-class="[ 'input-date', 'square' ]"
                                    :language="calendarTH"
                                    :full-month-name="true"
                                    calendar-class="datepicker-box"
                                    placeholder="ถึงวันที่"
                                    format="dd/MM/yyyy">
                                </datepicker>
                                <b-icon icon="calendar"></b-icon>
                            </div>
                            <div class="input-field">
                                <dropdown
                                    v-model="form.status"
                                    placeholder="ทั้งหมด"
                                    :classes="{ input: [ 'square', 'input-option' ] }"
                                    :options="optionStatus">
                                </dropdown>
                            </div>
                            <div class="mt-2 text-center">
                                <button class="btn btn-danger" @click="exportExcel()">ออกรายงาน</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </transition>
</template>

<script>
import { th } from 'vuejs-datepicker/dist/locale';
import Helper from '@/services/helper';
import SellerService from '@/services/sellerService';

export default {
    data() {
		return {
            isActive: false,
            form: this.initForm(),
            calendarTH: th
		};
	},
	props: {
		isDisplay: Boolean,
        optionStatus: Array,
        branchId: String
	},
    watch: {
        async isDisplay(value) {
            this.isActive = value;
            this.$root.$emit('rootSetContentScrollable', value);

            if (value) {
                this.form = this.initForm();
            }
        }
    },
    destroyed() {
        this.$root.$emit('rootSetContentScrollable', false);
    },
	methods: {
        hide(isReload) {
            this.$emit('close', { isReload: isReload });
        },
        initForm() {
			return {
				dateFrom: '',
				dateTo: '',
				status: '',
                branchId: this.branchId
			};
		},
        async exportExcel() {
            let loader = this.$modalLoader.render();
			const result = await SellerService.exportOrder(this.form);
			loader.hide();

			if (result.data?.success) {
                Helper.fileDownloadByApi(result.data?.file);
			} else {
				this.$swal(Helper.warningAlert('ไม่สามารถดาวน์โหลดไฟล์ Excel ได้', result.data?.message));
			}
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.modal-form {
    max-width: 300px;

    .btn {
        font-size: 13px;
        padding: 7px 14px;
    }
}

::v-deep .input-field {
    input {
        font-size: 13px;
        padding: 15px;

        &[readonly] {
            background: #fff;
        }
    }
}
</style>