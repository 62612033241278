<template>
	<div class="search">
		<div class="form">
			<input type="text"
				@keyup.enter="submitSearch()"
				v-model="keyword"
				placeholder="กรองข้อมูลโดย ทะเบียนรถ / ชื่อลูกค้า"
				maxlength="100" />
			<button class="btn btn-danger square" @click="submitSearch()">กรองผลลัพธ์</button>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			keyword: ''
		}
	},
	methods: {
		submitSearch() {
			this.$emit('submitSearch', this.keyword);
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.search {
	.form {
		position: relative;

		input {
			width: 100%;
			padding: 10px 15px;
			border: 1px solid #ccc;
			outline: none;
		}

		.btn {
			position: absolute;
			top: 1px;
			bottom: 1px;
			right: 1px;
			width: 100px;
		}
	}
}
</style>