<template>
	<div>
		<seller-branch-filter
			class="mb-3"
			labelText="คำสั่งซื้อสาขา"
			@changeBranch="changeBranch($event)">
		</seller-branch-filter>

		<div class="filter-bar mb-2">
			<div class="d-none d-lg-block">
				<button v-for="(item, index) in statusList" :key="index"
					:class="{ 'active': activeStatus === item.statusCode }"
					@click="changeFilter(item.statusCode)">
					{{ item.statusText }}<span v-if="item.totalOrder > 0">({{ item.totalOrder }})</span>
				</button>
			</div>

			<div class="sort-option d-md-block d-lg-none" v-if="optionStatus">
				<select
					v-model="activeStatus"
					@change="changeFilter($event.target.value)"
					class="input-option">
					<option v-for="(item, index) in optionStatus" :key="index" :value="item.id">{{ item.name }}</option>
				</select>
			</div>
		</div>

		<div class="filter-result mb-3">
			<seller-search-order
				@submitSearch="customerFilter($event)">
			</seller-search-order>
		</div>

		<div class="sub-operation mt-3 mb-3">
			<button class="btn btn-secondary" @click="openModalOrderExport()">
				<b-icon icon="cloud-download" class="icon"></b-icon> ออกรายงาน
			</button>

			<div class="sort-by">
				<label class="d-none d-sm-block">เรียงโดย</label>
				<select
					@change="changeSort($event)">
					<option v-for="(item, index) in optionSort" :key="index" :value="item.id">
						{{ item.name }}
					</option>
				</select>
			</div>
		</div>

		<div class="loading" v-if="!isResultReady">
			<div class="item">
				<loader-content :isDisplay="true"></loader-content>
			</div>
		</div>

		<seller-order-card
			class="mb-3"
			@updateOrder="reloadOrder()"
			:content="item" v-for="(item, index) in resultList" :key="index">
		</seller-order-card>

		<paging
			@page="changePage($event)"
			:activePage="activePage"
			:totalPage="totalPage"
			class="mt-4 mb-2">
		</paging>

		<div class="no-data" v-if="isResultReady && resultList.length === 0">
			<b-icon icon="box-seam"></b-icon>
			<span>ไม่มีรายการสั่งซื้อสำหรับสถานะที่เลือก</span>
		</div>

		<modal-order-export
			@close="closeModalOrderExport()"
			:branchId="branchId"
			:optionStatus="optionStatus"
			:isDisplay="isShowModalExport">
		</modal-order-export>
	</div>
</template>

<script>
import SellerBranchFilter from '@/components/seller/BranchFilter';
import SellerSearchOrder from '@/components/seller/orders/SearchForm';
import SellerOrderCard from '@/components/seller/orders/OrderCard';
import SellerService from '@/services/sellerService';
import RouterPath from '@/router/path';
import ModalOrderExport from '@/components/seller/orders/ModalExport';

export default {
	components: {
		SellerBranchFilter,
		SellerSearchOrder,
		SellerOrderCard,
		ModalOrderExport
	},
	data() {
		return {
			isResultReady: false,
			statusList: [],
			resultList: [],
			keyword: '',
			activePage: 1,
			activeStatus: 0,
			totalPage: 0,
			branchId: '',
			optionStatus: [],
			isShowModalExport: false,
			selectedSort: 'appointmentDate',
			optionSort: this.initOptionSort(),
		}
	},
	mounted() {
		this.getOrderCount();
		this.getOrderList();
	},
	methods: {
		initOptionSort() {
			return [
				{ id: 'appointmentDate', name: 'วันนัดหมาย' },
				{ id: 'deliveryDate', name: 'วันที่จัดส่ง' },
				{ id: 'purchaseDate', name: 'วันที่ทำรายการ' },
				{ id: 'serviceDate', name: 'วันที่ติดตั้ง' }
			];
		},
		setupStatusOption() {
			this.optionStatus = [];

			this.statusList.forEach((item) => {
				this.optionStatus.push({
					id: item.statusCode,
					name: item.statusText + (item.totalOrder > 0 ? ' (' + item.totalOrder + ')' : '')
				});
			});
		},
		openModalOrderExport() {
			this.isShowModalExport = true;
		},
		closeModalOrderExport() {
			this.isShowModalExport = false;
		},
		reloadOrder() {
			this.getOrderCount();
			this.getOrderList();
		},
		async getOrderCount() {
			let loader = this.$modalLoader.render();
			const param = {
				branchId: this.branchId
			};
			const result = await SellerService.getOrderCount(param);

			if (result.data) {
				this.statusList = result.data;

				// Setup status option
				this.setupStatusOption();
			}

			loader.hide();
		},
		async getOrderList() {
			this.isResultReady = false;

			const param = this.setupParam();
            const result = await SellerService.getOrderList(param);

			if (result.status == 401) {
				this.$router.push(RouterPath.SELLER_LOGIN);
			}

			this.resultList = result.data.resultList;
			this.totalPage = result.data.totalPage;
            this.isResultReady = true;
		},
		setupParam() {
			const param = {
				page: this.activePage,
				status: this.activeStatus,
				keyword: this.keyword,
				sortBy: this.selectedSort,
				branchId: this.branchId
			};

			return param;
		},
		customerFilter(keyword) {
			this.activePage = 1;
			this.keyword = keyword;
			this.getOrderList();
		},
		changeFilter(status) {
			this.activePage = 1;
			this.activeStatus = status;
			this.getOrderList();
		},
		changeSort(e) {
			this.activePage = 1;
			this.selectedSort = e.target.value;
			this.getOrderList();
		},
		changePage(page) {
			this.activePage = page;
			this.getOrderList();
		},
		changeBranch(branchId) {
			this.activePage = 1;
			this.branchId = branchId;
			this.getOrderCount();
			this.getOrderList();
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.sub-operation {
	display: flex;
	justify-content: space-between;

	.btn {
		padding: 5px 15px;

		.icon {
			margin-right: 5px;
		}
	}

	.sort-by {
		display: flex;
		align-items: center;

		label {
			font-size: 13px;
			color: #999;
			margin: 0;
			margin-right: 10px;
		}

		select {
			cursor: pointer;
			padding: 5px 10px;
			font-size: 15px;
			border: 1px solid #6c757d;
			@include borderRadius(1000px);
		}
	}
}

.filter-bar {
	background: #c1c1c1;
	padding: 0;
	display: flex;
	align-items: center;

	button {
		outline: none;
		background: none;
		border: none;
		margin: 3px 10px;

		&.active {
			font-weight: bold;
			color: #0a58ca;
		}

		span {
			margin-left: 5px;
		}
	}
}

.loading {
	.item {
		height: 300px;
		margin-bottom: 20px;
	}
}

.sort-option {
	background: #7e7a7a;
	width: 150px;

	.input-option {
		width: 100%;
		height: 30px;
		background: none;
		border: none;
		color: #fff;
		text-align: center;

		@media only screen and (max-width: $screenMedium) {
			font-size: 14px;
		}

		option {
			color: #000;
		}
	}
}
</style>